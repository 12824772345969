
#slider {
    flex: 1 0 auto;
}
#slider .fixed-content {
    text-shadow: 2px 2px 2px black;
}
@media (min-width: 992px) {
    /* 
        We ensure that when header is fixed, we have a minimum of 102px of space before the fixed content
        This trick does not break the vertical alignment centered in the page
    */
#slider .fixed-content {
        padding-top:204px;
        transform: translate(0px, -102px);
}
}
#slider .swiper-wrapper {
    position:absolute;
    top:0px;
}
.swiper-slide {
    background-size: cover;
    background-color: rgb(255, 255, 255);
}
.swiper-slide.palais-de-justice {
    background-image: url(/1706ad94-bb8f-4eb8-9c04-a5ccae63cbab//img/bensadevot/nice/palais-de-justice-nice.jpg);
    background-repeat: no-repeat;
    background-position:center bottom;
}
.swiper-slide.promenade {
    background-image: url(/1706ad94-bb8f-4eb8-9c04-a5ccae63cbab//img/bensadevot/nice/promenade-nice.jpg); 
    background-repeat: no-repeat;
    background-position: center right;
}
.swiper-slide.gare-du-sud {
    background-image: url(/1706ad94-bb8f-4eb8-9c04-a5ccae63cbab//img/bensadevot/nice/gare-du-sud-nice-2.jpg);
    background-repeat: no-repeat;
    background-position:center bottom;
}
#slider p {
    font-size: 1.5rem;
    letter-spacing: 1px;
}



